import { useState, useCallback } from 'react';
import { useLocation } from 'wouter';
import { toast } from 'react-toastify';

export default function useFetch() {
  const [, setLocation] = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchAction = useCallback(async (params) => {
    let messageOk;
    if (params.messageOk) {
      messageOk = () => toast.success(params.messageOk, { position: "top-center" });
    }

    let messageKo;
    if (params.messageKo) {
      messageKo = () => toast.error(params.messageKo, { position: "top-center" });
    }

    setData(null);
    setError(null);
    setLoading(true);

    const apiCredentials = `Basic ${btoa(
      process.env.REACT_APP_API_USERNAME + ':' + process.env.REACT_APP_API_PASSWORD
    )}`;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${params.url}`, {
        method: params.method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: apiCredentials,
        },
        body: params.body ? JSON.stringify(params.body) : null,
      });

      if (!response.ok) {
        if (!params.url.includes('auth') && response.status === 401) {
          setLocation('/login');
        } else {
          throw new Error(response.statusText);
        }
      } else {
        const data = await response.json();
        setData(data);
        console.log('Response Data:', data); // Log de los datos de la respuesta
        messageOk && messageOk();

        // Redirigir si se proporciona un destino
        if (params.redirectTo) {
          setLocation(params.redirectTo);
        }
      }
    } catch (error) {
      console.log('Fetch error: ', error);
      if (error.name !== 'AbortError') {
        messageKo && messageKo();
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  }, [setLocation]);

  return [{ loading, data, error }, fetchAction];
}

// bona dalt


// import { useState, useCallback } from "react";
// import { useLocation } from "wouter";
// import { toast } from "react-toastify";

// export default function useFetch() {
//   const [, setLocation] = useLocation();
//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);

//   const fetchAction = useCallback(
//     async (params) => {
//       setData(null);
//       setError(null);
//       setLoading(true);

//       const apiCredentials = `Basic ${btoa(
//         process.env.REACT_APP_API_USERNAME +
//           ":" +
//           process.env.REACT_APP_API_PASSWORD
//       )}`;

//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/${params.url}`,
//           {
//             method: params.method,
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: apiCredentials,
//             },
//             body: params.body ? JSON.stringify(params.body) : null,
//           }
//         );

//         if (!response.ok) {
//           let errorMessage = params.messageKo || "Error inesperado.";

//           // Intentar capturar mensaje de error del backend
//           try {
//             const errorData = await response.json();
//             if (errorData?.error) {
//               errorMessage = errorData.error;
//             }
//           } catch (err) {
//             console.warn("No se pudo extraer el mensaje del backend.");
//           }

//           toast.error(errorMessage, { position: "top-center" });
//           throw new Error(errorMessage);
//         }

//         // Si la solicitud es exitosa
//         const responseData = await response.json();
//         setData(responseData);
//         params.messageOk &&
//           toast.success(params.messageOk, { position: "top-center" });

//         // Redirigir si se especifica
//         if (params.redirectTo) {
//           setLocation(params.redirectTo);
//         }
//       } catch (err) {
//         console.error("Error en la solicitud:", err);
//         setError(err);
//       } finally {
//         setLoading(false);
//       }
//     },
//     [setLocation]
//   );

//   return [{ loading, data, error }, fetchAction];
// }

// tmb funciona

// import { useState, useCallback } from 'react';
// import { useLocation } from 'wouter';
// import { toast } from 'react-toastify';

// export default function useFetch() {
//   const [, setLocation] = useLocation();
//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);

//   const fetchAction = useCallback(async (params) => {
//     let messageOk;
//     if (params.messageOk) {
//       messageOk = () => toast.success(params.messageOk, { position: "top-center" });
//     }

//     let messageKo;
//     if (params.messageKo) {
//       messageKo = () => toast.error(params.messageKo, { position: "top-center" });
//     }

//     setData(null);
//     setError(null);
//     setLoading(true);

//     const apiCredentials = `Basic ${btoa(
//       process.env.REACT_APP_API_USERNAME + ':' + process.env.REACT_APP_API_PASSWORD
//     )}`;

//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/${params.url}`, {
//         method: params.method,
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: apiCredentials,
//         },
//         body: params.body ? JSON.stringify(params.body) : null,
//       });

//       if (!response.ok) {
//         if (!params.url.includes('auth') && response.status === 401) {
//           setLocation('/login');
//         } else {
//           throw new Error(response.statusText);
//         }
//       } else {
//         const data = await response.json();
//         setData(data);
//         console.log('Response Data:', data); // Log de los datos de la respuesta
//         messageOk && messageOk();
//       }
//     } catch (error) {
//       console.log('Fetch error: ', error);
//       if (error.name !== 'AbortError') {
//         messageKo && messageKo();
//         setError(error);
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [setLocation]);

//   return [{ loading, data, error }, fetchAction];
// }


// import { useState, useCallback } from 'react';
// import { useLocation } from 'wouter';
// import { toast } from 'react-toastify';

// export default function useFetch() {
//   // Wouter redirect init
//   const [, setLocation] = useLocation();

//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);

//   const fetchAction = useCallback(async (params) => {
//     // Init toast OK
//     let messageOk;
//     if (params.messageOk) {
//       messageOk = () =>
//         toast.success(params.messageOk, {
//           position: "top-center",
//         });
//     }
//     // Init toast KO
//     let messageKo;
//     if (params.messageKo) {
//       messageKo = () =>
//         toast.error(params.messageKo, {
//           position: "top-center",
//         });
//     }
//     setData(null);
//     setError(null);

//     setLoading(true);

//     console.log(process.env.REACT_APP_API_USERNAME);
//     console.log(process.env.REACT_APP_API_PASSWORD);
//     console.log(process.env.REACT_APP_API_URL);

//     const apiCredentials = `Basic ${btoa(
//       process.env.REACT_APP_API_USERNAME + ':' + process.env.REACT_APP_API_PASSWORD
//     )}`;

//     let response;
//     try {
//       response = await fetch(`${process.env.REACT_APP_API_URL}/${params.url}`, {
//         method: params.method,
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: apiCredentials,
//         },
//         body: params.body ? JSON.stringify(params.body) : null,
//       });

//       if (!response.ok) {
//         // If fetch url is not for auth and response code is 401
//         if (!params.url.includes('auth') && response.status === 401) {
//           setLocation('/login');
//         } else {
//           throw new Error(response.statusText);
//         }
//       } else {
//         const data = await response.json();
//         setData(data);
//         console.log('Response Data:', data); // Log de los datos de la respuesta

//         // Toast OK (if defined)
//         messageOk && messageOk();
//       }
//     } catch (error) {
//       console.log('Fetch error: ', error);
//       if (error.name !== 'AbortError') {
//         // Toast KO (if defined)
//         messageKo && messageKo();

//         setError(error);
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [setLocation]);

//   return [{ loading, data, error }, fetchAction];
// }


// REACT HOOKS
// import { useState } from 'react'

// // useLocation()
// import { useLocation } from 'wouter'

// export default function useFetch() {
//   // useLocation():
//   const [, setLocation] = useLocation()

//   // useState():
//   const [loading, setLoading] = useState(false)
//   const [isCalled, setIsCalled] = useState(false)
//   const [data, setData] = useState(null)
//   const [error, setError] = useState(null)
//   const [message, setMessage] = useState(null)

//   async function fetchAction(params) {
//     // Reiniciamos estados:
//     setIsCalled(true)
//     setData(null)
//     setError(null)
//     setMessage(null)
//     setLoading(true)

//     // Crecendiales API:
//     const apiCredentials = `Basic ${btoa(
//       process.env.REACT_APP_API_USERNAME +
//         ':' +
//         process.env.REACT_APP_API_PASSWORD
//     )}`

//     try {
//       // Cabeceras:
//       const headers = params.formData
//         ? { Accept: 'multipart/form-data', Authorization: apiCredentials }
//         : { 'Content-Type': 'application/json', Authorization: apiCredentials }

//       // Cuerpo:
//       const body = params.formData
//         ? params.formData
//         : JSON.stringify(params.body)

//       // Respuesta:
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/${params.url}`,
//         {
//           method: params.method,
//           headers,
//           body
//         }
//       )

//       // ¿Ha ido bien? Sí:
//       if (response.ok) {
//         // Guardamos datos de la respuesta:
//         const responseData = await response.json()

//         setData(responseData)
//         setMessage(responseData.message)
//       }

//       // ¿Ha ido bien? No:
//       if (!response.ok) {
//         // Verificamos error de autorización:
//         if (!params.url.includes('auth') && response.status === 401) {
//           // Redirigimos a la pantalla login:
//           setLocation('/login')
//         } else {
//           // Guardamos errores de la respuesta:
//           const errorData = await response.json()
//           setError(errorData.error)
//           console.log(
//             'useFetch() detailedError from API call fail: ',
//             errorData.detailedError
//           )
//         }
//       }
//     } catch (error) {
//       setError(error)
//     } finally {
//       setLoading(false)
//     }
//   }

//   return [
//     {
//       loading,
//       isCalled,
//       data,
//       error,
//       message
//     },
//     fetchAction
//   ]
// }
